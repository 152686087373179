import img1 from '@pages/Install/components/Comments/assets/1.png'
import img2 from '@pages/Install/components/Comments/assets/2.png'
import img3 from '@pages/Install/components/Comments/assets/3.png'
import img4 from '@pages/Install/components/Comments/assets/4.png'
import img5 from '@pages/Install/components/Comments/assets/5.png'
import img6 from '@pages/Install/components/Comments/assets/6.png'
import img7 from '@pages/Install/components/Comments/assets/7.png'
import img8 from '@pages/Install/components/Comments/assets/8.png'
import img9 from '@pages/Install/components/Comments/assets/9.png'
import img10 from '@pages/Install/components/Comments/assets/10.png'
import img11 from '@pages/Install/components/Comments/assets/11.png'
import img12 from '@pages/Install/components/Comments/assets/12.png'
import img13 from '@pages/Install/components/Comments/assets/13.png'
// import img14 from '@pages/Install/components/Comments/assets/14.png'
// import img15 from '@pages/Install/components/Comments/assets/15.png'
// import img16 from '@pages/Install/components/Comments/assets/16.png'
// import img17 from '@pages/Install/components/Comments/assets/17.png'
// import img18 from '@pages/Install/components/Comments/assets/18.png'
// import img19 from '@pages/Install/components/Comments/assets/19.png'
// import img20 from '@pages/Install/components/Comments/assets/20.png'
// import img21 from '@pages/Install/components/Comments/assets/21.png'
// import img22 from '@pages/Install/components/Comments/assets/22.png'
// import img23 from '@pages/Install/components/Comments/assets/23.png'

export const listComments = [
  {
    icon: img1,
    name: 'অর্ণভ বন্দ্যোপাধ্যায়',
    countStarts: 5,
    date: 'April 8, 2024',
    comment:
      'Crazy Time - এটি একটি ভাল অ্যাপ্লিকেশন, যেখানে আপনি স্টেক এবং ক্যাসিনোতে ভাল অর্থ উপার্জন করতে পারেন।',
    peopleFound: 2,
  },
  {
    icon: img2,
    name: 'সৌরভ মুখার্জি',
    countStarts: 16,
    date: 'April 1, 2024',
    comment: '"Crazy Time" একটি অসাধারণ অ্যাপ্লিকেশন, যা আমি সবার কাছে সুপারি করছি।',
    peopleFound: 16,
  },
  {
    icon: img3,
    name: 'অনির্বাণ চক্রবর্তী',
    countStarts: 5,
    date: 'March 29, 2024',
    comment:
      'অসাধারণভাবে কাজ করে এবং দ্রুত অর্থ উত্তোলন করে। আমি সবাকে এই অ্যাপ্লিকেশনটি ডাউনলোড করার জন্য অনুপ্রেরণা দিচ্ছি।',
    peopleFound: 54,
  },
  {
    icon: img4,
    name: 'দেবাশিষ দত্ত',
    countStarts: 4,
    date: 'March 25, 2024',
    comment:
      'ডাউনলোড করুন করা হয়েছে অসাধারণ "Crazy Time" অনলাইন ক্যাসিনো অ্যাপ, ভালো জয়, উচ্চ কোয়ালিটির মুল্যায়ন এবং দ্রুত পেআউট। খুব ভালো!!!',
    peopleFound: 1,
  },
  {
    icon: img5,
    name: 'অভিজিৎ সেন',
    countStarts: 5,
    date: 'March 20, 2024',
    comment:
      'দারুণ অ্যাপ। আমরা এটি সুখ সাথে ব্যবহার করি। খুব সুবিধাজনক এবং বোঝার জন্য। খেলা করা স্পোর্টসে দান করা মজাদার.. আমি এই অ্যাপ্লিকেশনটি সুপারি করছি...',
    peopleFound: 22,
  },
  {
    icon: img6,
    name: 'কামল ঘোষ',
    countStarts: 5,
    date: 'March 9, 2024',
    comment:
      'আস্চর্য্যকর খেলা, আমার খুব পছন্দ হয়েছে, অনেক সুবিধা, অনেক সাত্ত্বিক ভাবনা, অনেক অতিরিক্ত ফাংশন, যদি কিছু অসুবিধা থাকে যা সংশোধনের জন্য কাজ করতে হয়, তবে সামগ্রিকভাবে আমি অ্যাপ্লিকেশন এবং এটির সাথে Crazy Time খুব বিশেষ ভাবে সন্তুষ্ট, আমি সত্যিই সবার কাছে এটি পছন্দ হয়েছে এবং মনোভাব ভালো ছিল।',
    peopleFound: 6,
  },
  {
    icon: img7,
    name: 'কামল ঘোষ',
    countStarts: 5,
    date: 'March 1, 2024',
    comment:
      'আস্চর্য্যকর খেলা, আমার খুব পছন্দ হয়েছে, অনেক সুবিধা, অনেক সাত্ত্বিক ভাবনা, অনেক অতিরিক্ত ফাংশন, যদি কিছু অসুবিধা থাকে যা সংশোধনের জন্য কাজ করতে হয়, তবে সামগ্রিকভাবে আমি অ্যাপ্লিকেশন এবং এটির সাথে Crazy Time খুব বিশেষ ভাবে সন্তুষ্ট, আমি সত্যিই সবার কাছে এটি পছন্দ হয়েছে এবং মনোভাব ভালো ছিল।',
    peopleFound: 0,
  },
  {
    icon: img8,
    name: 'নীলাম আকতার',
    countStarts: 5,
    date: 'February 26, 2024',
    comment:
      'গেমটি অসাধারণ এবং এখানের বোনাসগুলি অনেক মজার। আমি আমার সব বন্ধুদের এটি খেলার পরামর্শ দেই!',
    peopleFound: 34,
  },
  {
    icon: img9,
    name: 'আরিফ আহমেদ',
    countStarts: 5,
    date: 'February 24, 2024',
    comment:
      'এই অ্যাপটি একেবারে চমৎকার! বিভিন্ন ধরণের গেমের একটি বিশাল জগত যাতে কখনই আপনি বিরক্ত হবেন না।',
    peopleFound: 9,
  },
  {
    icon: img10,
    name: 'জুবায়ের রহমান',
    countStarts: 5,
    date: 'February 22, 2024',
    comment:
      'এটা আসলেই একটা জিনিস! গেমটি আমার সমস্ত প্রত্যাশা একেবারে ছাড়িয়ে গেছে। এটি একইসাথে মজার ও অসাধারণ এবং এর বোনাস আরও অতিরিক্ত মজা নিয়ে আসে। ধন্যবাদ!',
    peopleFound: 1,
  },
  {
    icon: img11,
    name: 'তাসনিম হাকিম',
    countStarts: 4,
    date: 'February 18, 2024',
    comment:
      'প্রথম দেখাতেই ভালোবাসা যাকে বলে! আমি এক্টিভলি খেলি এবং এটা প্রতি মিনিটে মিনিটে আমার মাঝে আনন্দ নিয়ে আসে।',
    peopleFound: 22,
  },
  {
    icon: img12,
    name: 'আরিফ আহমেদ',
    countStarts: 4,
    date: 'February 1, 2024',
    comment:
      'আমি আমার একাউন্টে টাকা ভরেছি, খেলেছি, এবং দেখুন, আমি জিতেছি! আমি নিজে নিজে চেষ্টা করার আগে এইসব গল্পগুলিতে খুব কমই বিশ্বাস করতাম। আর এখন আমি এটা প্রত্যেককে সাজেস্ট করলাম!',
    peopleFound: 43,
  },
  {
    icon: img13,
    name: 'ইয়াসমিন আবদুল্লাহ',
    countStarts: 5,
    date: 'January 30, 2024',
    comment:
      'আমি আনন্দে আত্মহারা হয়ে গিয়েছিলাম এটা দেখে যে কীভাবে আমার বন্ধু ধারাবাহিকভাবে বড় অঙ্কের অর্থ জিতেছে। সমস্ত কিছু সাথে সাথেই কার্যকর হয়নি, তবে জ্যাকপট একেবারে সমস্ত বিনিয়োগ আর ইনভেস্টমেন্ট আমাদের পরিশোধ করে দিয়েছে। অবিশ্বাস্য এ বিজয় এবং আনন্দের জন্য ধন্যবাদ!',
    peopleFound: 14,
  },
  // {
  //   icon: img14,
  //   name: 'Sumit Saha',
  //   countStarts: 4,
  //   date: 'January 28, 2024',
  //   comment:
  //     'Hello friends, I want to share with you my impression about Crazy time application!!! Although I am not a gambling person, I was impressed by this application, you can bet safely and win, and withdrawing funds is very convenient. Bright and clear graphics! In general, good luck to all beginners and enthusiastic players',
  //   peopleFound: 67,
  // },
  // {
  //   icon: img15,
  //   name: 'Amitabh Biswas',
  //   countStarts: 5,
  //   date: 'January 20, 2024',
  //   comment:
  //     'Crazy time is a great and easy earning company. I downloaded the application and I do not regret it. I play with pleasure and withdraw the money I earn to a bank account or even e-wallet. I advise you to try playing, you will not regret it. I have already recommended it to all my friends and acquaintances',
  //   peopleFound: 0,
  // },
  // {
  //   icon: img16,
  //   name: 'Sunil Raychoudhury',
  //   countStarts: 5,
  //   date: 'January 19, 2024',
  //   comment:
  //     'A very good Crazy time app. I like it very much. I recommend everyone to download this wonderful, amazing application, you can do a lot and it works very well. I highly recommend downloading this application, it is very well done. Thanks to the developers for such a wonderful application',
  //   peopleFound: 12,
  // },
  // {
  //   icon: img17,
  //   name: 'Aniruddh Kar',
  //   countStarts: 5,
  //   date: 'January 28, 2024',
  //   comment:
  //     "Crazy time is the top best app for betting, it is absolutely amazing and starts from 20 to 12,000. Simply and easily. I used to play such bets and always lose, but in Aviator everything is fair, I never lose money and withdraw money with any payment method. Apart from this, there are many other wallets in which you can transfer money. If you don't believe me, check it out yourself!",
  //   peopleFound: 23,
  // },
  // {
  //   icon: img18,
  //   name: 'Srijit Rahman',
  //   countStarts: 5,
  //   date: 'January 12, 2024',
  //   comment:
  //     'Crazy time, great application for everyone and it has no problems and everything else is fine. Simple, intuitive application. Even a newbie can understand it, so you can download, install and start playing. In this direction, you can not only relax but also earn money by playing this game. Download and install.',
  //   peopleFound: 19,
  // },
  // {
  //   icon: img19,
  //   name: 'Maushum Choudhury',
  //   countStarts: 5,
  //   date: 'January 11, 2024',
  //   comment:
  //     'Crazy time is an excellent application, clear interface, excellent graphics, no lag. Even a novice can figure it out. But the main advantage is that you can also earn extra money here! This is absolutely great! I have been using this application for a long time, it has never let me down, the output to the card is very convenient! In general, I recommend it to everyone.',
  //   peopleFound: 43,
  // },
  // {
  //   icon: img20,
  //   name: 'Ranjit Chakravarty',
  //   countStarts: 5,
  //   date: 'January 3, 2024',
  //   comment:
  //     "A very excellent company Crazy time, big wins, fast payouts and high possibilities, many sports games, very convenient and easy to use, even a newbie can figure it out in a few clicks and you are already earning money. So far, I've only heard mediocre comments about this app. I recommend everyone to try",
  //   peopleFound: 26,
  // },
  // {
  //   icon: img21,
  //   name: 'Ajit Das',
  //   countStarts: 5,
  //   date: 'January 3, 2024',
  //   comment:
  //     "Very good application, I did not even think that you can win money on Crazy time, I advise everyone to install the Crazy time application, place bets and win money, because as they say, there is never too much money Have a chance to win with pleasure, and it's great, good luck to everyone who joins Aviator, good luck placing bets in this wonderful application.",
  //   peopleFound: 23,
  // },
  // {
  //   icon: img22,
  //   name: 'Manish Mukhopadhyay',
  //   countStarts: 5,
  //   date: 'January 3, 2024',
  //   comment:
  //     'Very nice application. I never thought that I could get a lot of money. I recommend everyone to download this application',
  //   peopleFound: 19,
  // },
  // {
  //   icon: img23,
  //   name: 'Vikram Pande',
  //   countStarts: 5,
  //   date: 'December 27, 2023',
  //   comment:
  //     "Crazy time app is really good. I didn't think it was that easy. You install it and it works immediately. Overall very good. So I thought it was another scam. But I was sure that was not the case.",
  //   peopleFound: 67,
  // },
]
