import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`

export const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

// ListImage

export const ListImage = styled.div`
  margin-top: 48px;
  display: flex;
  gap: 8px;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
`

export const ItemImage = styled.img`
  background-size: contain;
  border-radius: 8px;
  border-width: 0;
  box-shadow:
    rgba(60, 64, 67, 0.3) 0 1px 2px 0,
    rgba(60, 64, 67, 0.15) 0 1px 3px 1px;
  cursor: pointer;
  height: 204px;
  min-width: 115px;
`

// ListImage

// ContentAbout
export const ContentAbout = styled.div``
export const TitleAbout = styled.h2`
  fill: rgb(32, 33, 36);
  stop-color: rgb(32, 33, 36);
  color: rgb(32, 33, 36);
  display: flex;
  font-size: 1.125rem;
  font-weight: 500;
  justify-content: space-between;
  letter-spacing: 0;
  line-height: 1.5rem;
  padding: 20px 0;
`

export const DescriptionAbout = styled.div`
  border: 0;
  box-sizing: border-box;
  color: rgb(95, 99, 104);
  display: -webkit-box;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0142857em;
  line-height: 1.25rem;
  margin: 0;
  max-height: 7.5rem;

  overflow: hidden; /* обрезает текст, который выходит за границы элемента */
  text-overflow: ellipsis; /* добавляет многоточие в конце обрезанного текста */
  width: 100%; /* установите нужную вам ширину */
`

export const ContentUpdate = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 24px;
`

export const TitleUpdate = styled.div`
  fill: rgb(32, 33, 36);
  stop-color: rgb(32, 33, 36);
  color: rgb(32, 33, 36);
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.0178571em;
  line-height: 1.25rem;
`
export const DateUpdate = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0142857em;
  line-height: 1.25rem;
  margin-top: 4px;
  color: rgb(95, 99, 104);
`
// AboutContent

// Data safety

export const WrapperData = styled.div`
  text-size-adjust: 100%;
  fill: #5f6368;
  -webkit-tap-highlight-color: transparent;
  border: 1px solid #dadce0;
  border-radius: 8px;
  box-sizing: border-box;
  color: inherit;
  font: inherit;
  font-size: 100%;
  letter-spacing: 0.0142857143em;
  margin: 30px 0 0;
  padding: 20px 20px 0;
  text-rendering: optimizeLegibility;
  vertical-align: initial;
  height: 98px;
`

export const TextData = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0142857em;
  line-height: 1.25rem;
  margin-top: 4px;
  color: rgb(95, 99, 104);
`

// Data safety
