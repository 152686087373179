import styled from 'styled-components'

export const WrapperPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
  width: 100%;
  flex-direction: column;
`

export const Container = styled.div`
  height: 100%;
  width: calc(100% - 48px);
  min-width: 300px; /* установите нужную вам минимальную ширину */
  margin: 0 auto;
  flex-grow: 1;
`
